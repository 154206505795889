<template>
    <div class="ol-wrapper ol-wrapper--large">
        <div class="ol-checkout-content">
            <div class="ol-checkout-content__payment">
                <template v-if="loading">
                    <skeleton-loader v-for="i in Array(6)" :key="i" :height="90"></skeleton-loader>
                </template>
                <template v-else>
                    <accordion-payment :payment_list="paymentList" :last_payment="user.last_payment_code" @selectedPayment="handleSelectedPayment($event)" @selectedTenor="handleSelectedTenor($event)" @fee="handleFee($event)"></accordion-payment>
                </template>
            </div>
        </div>
        <div class="ol-checkout-nav">
            <router-link to="/checkout" class="ol-btn ol-btn--secondary"><i class="ol-fa fas fa-arrow-left"></i>&emsp;Kembali</router-link>
            <router-link to="/checkout/review" id="payments-btn-next" class="ol-btn ol-btn-disable">Selanjutnya&emsp;<i class="ol-fa fas fa-arrow-right"></i></router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import SkeletonLoader from '../../../components/loading/skeletonLoader.vue';
import AccordionPayment from '../../../components/transaction/checkout/AccordionPayment.vue';
export default {
    name:"CheckoutPayment2",
    components: {
        SkeletonLoader,
        AccordionPayment,
    },
    data() {
        return {
            loading: false,
            picked: null,
            tenor: null,
            fee: null,
        }
    },
    computed: {
        ...mapGetters('transaction/checkout', ['paymentList']),
        ...mapGetters("auth", ["user"]),
    },
    methods: {
        ...mapActions('transaction/checkout', ['fetchPaymentList']),
        handleSelectedPayment(value) {
            let dataPayment = _.flatMap(this.paymentList, (item) => this.filterMatched(item, value));
            this.picked = _.head(dataPayment);
        },
        handleSelectedTenor(value) {
            this.tenor = value;
            this.$store.commit('transaction/checkout/setTenor', value);
        },
        handleFee(value) {
            this.fee = value;
            this.$store.commit('transaction/checkout/setFee', value);
        },
        filterMatched(x, value) {
            let matched = _.filter(x, function(y) {
                if(y.code == value) return y;
            });
            return matched;
        },
    },
    created() {
        if(this.order) this.$router.push("/profile/orders");
        this.$store.commit('transaction/checkout/setOrder', null);
    },
    async mounted() {
        this.loading = true;
        await this.fetchPaymentList();
        let dataPayment = _.flatMap(this.paymentList, (item) => this.filterMatched(item, this.user.last_payment_code));
        this.picked = _.head(dataPayment);
        this.loading = false;
    },
    watch: {
        picked: function(value) {
            if(value) {
                this.$store.commit('transaction/checkout/setPayment', value);
                $("a#payments-btn-next").removeClass("ol-btn-disable");
            }
            else {
                $("a#payments-btn-next").addClass("ol-btn-disable");
            }
        },
    },
}
</script>

<style scoped>
.skeleton {
    margin-bottom: 5px;
}
.ol-btn-disable{
    background-color: #aaa;
    border-color: #aaa;
    cursor: default;
    pointer-events: none;
}
</style>