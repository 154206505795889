<template>
    <div id="accordion-payment">
        <div class="card-payment">
            <div class="card-header">
                <input type="radio" id="radio1" name="type_bayar" @click="showCollapsible('#collapsible1')">
                <label for="radio1">Transfer Virtual Account</label>
                <!-- <div class="img">
                    <img src="https://cdn.morefurniture.id/MORE/payment/bca.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/mandiri.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/permata.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/og-maybank.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/bni.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/danamon.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/bnc.png" class="img-item">
                </div> -->
            </div>
            <div class="card-collapsible" id="collapsible1">
                <!-- <label>Pilih Bank Penyedia</label> -->
                <div id="dd_VA"></div>
            </div>
        </div>
        <div class="card-payment">
            <div class="card-header">
                <input type="radio" id="radio2" name="type_bayar" @click="showCollapsible('#collapsible2')">
                <label for="radio2">Kartu Kredit (VISA, Mastercard, JCB, American Express)</label>
                <!-- <div class="img">
                    <img src="https://cdn.morefurniture.id/MORE/payment/visa.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/mastercard.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/jcb.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/american.png" class="img-item">
                </div> -->
            </div>
            <div class="card-collapsible" id="collapsible2">
                <div class="card-body">
                    - Detail Kartu dapat diisi di halaman selanjutnya<br>
                    - Ketika mengisi detail kartu, kamu sudah tidak dapat mengubah detail pembelian. 
                </div>
            </div>
        </div>
        <div class="card-payment" style="display:none;">
            <div class="card-header">
                <input type="radio" id="radio3" name="type_bayar" @click="showCollapsible('#collapsible3')">
                <label for="radio3">Cicilan 0% Kartu Kredit</label>
                <div class="img">
                    <img src="https://cdn.morefurniture.id/MORE/payment/mandiri.png" class="img-item">
                </div>
            </div>
            <div class="card-collapsible" id="collapsible3">
            </div>
        </div>
        <div class="card-payment">
            <div class="card-header">
                <input type="radio" id="radio4" name="type_bayar" @click="showCollapsible('#collapsible4')">
                <label for="radio4">PayLater & Cicilan</label>
                <!-- <div class="img">
                    <img src="https://cdn.morefurniture.id/MORE/payment/akulaku.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/kredivo.png" class="img-item">
                </div> -->
            </div>
            <div class="card-collapsible" id="collapsible4">
                <div class="card-body">
                        - Pastikan kamu sudah punya akun PayLayter yang kamu pilih dan dapat limit untuk belanja<br>
                        - Kamu akan diarahkan ke halaman Aplikasi PayLater. 
                </div>
                <div class="break-1"></div>
                <div id="dd_CicilanTanpaKartu"></div>
            </div>
        </div>
        <div class="card-payment">
            <div class="card-header">
                <input type="radio" id="radio5" name="type_bayar" @click="showCollapsible('#collapsible5')">
                <label for="radio5">Dompet Digital</label>
                <!-- <div class="img">
                    <img src="https://cdn.morefurniture.id/MORE/payment/ovo.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/og-dana.png" class="img-item">
                </div> -->
            </div>
            <div class="card-collapsible" id="collapsible5">
                <div class="card-body">
                    Pastikan anda mempunyai Aplikasi Dompet Digital yang anda pilih
                    
                </div>
                <div class="break-1"></div>
                <div id="dd_Ewallet"></div>
            </div>
        </div>
        <div class="card-payment" style="display:none;">
            <div class="card-header">
                <input type="radio" id="radio6" name="type_bayar" @click="showCollapsible('#collapsible6')">
                <label for="radio6">Gerai Alfa / Indomaret</label>
                <!-- <div class="img">
                    <img src="https://cdn.morefurniture.id/MORE/payment/alfamart.png" class="img-item">
                    <img src="https://cdn.morefurniture.id/MORE/payment/indomaret.png" class="img-item">
                </div> -->
            </div>
            <div class="card-collapsible" id="collapsible6">
                <div class="card-body">
                        - Tagihan sudah termasuk biaya transaksi. Pembayaran di gerai retail lain akan dikenakan biaya transaksi berbeda. <br>
                        - Dapatkan kode pembayaran setelah klik "Bayar". 
                </div>
                <div class="break-1"></div>
                <div id="dd_Minimarket"></div>
            </div>
        </div>
    </div>
</template>

<script>
require('@/assets/js/ddslick/ddslick.min.js');
import { mapGetters } from 'vuex';
import helpme from '@/util/helpme';
export default {
    name: "AccordionPayment",
    props: ["payment_list", "last_payment"],
    data() {
        return {
            selectedElem: "",
            selectedPayment: "",
        }
    },
    computed: {
        ...mapGetters('transaction/cart', ['grandtotal']),
        grandtotal_num: function() {
            return helpme.toNumberOnly(this.grandtotal);
        },
        dataVA: function() {
            let data = this.payment_list['Virtual Account'];
            let _return = [];
            if(data) {
                let _this = this;
                data.map(function(item) {
                    let obj = {};
                    (item.code == _this.last_payment) ? obj.selected = true : obj.selected = false;
                    obj.text = item.name;
                    obj.value = item.code;
                    obj.imageSrc = item.icon_url;
                    _return.push(obj);
                });
            }
            return _return;
        },
        dataCicilan: function() {
            return this.payment_list['Cicilan'];
        },
        dataCicilanTanpaKartu: function() {
            let data = this.payment_list['Cicilan Tanpa Kartu'];
            let _return = [];
            if(data) {
                let _this = this;
                data.map(function(item) {
                    let obj = {};
                    (item.code == _this.last_payment) ? obj.selected = true : obj.selected = false;
                    obj.text = item.name;
                    obj.value = item.code;
                    obj.imageSrc = item.icon_url;
                    _return.push(obj);
                });
            }
            return _return;
        },
        dataEwallet: function() {
            let data = this.payment_list['E-wallet'];
            let _return = [];
            if(data) {
                let _this = this;
                data.map(function(item) {
                    let obj = {};
                    (item.code == _this.last_payment) ? obj.selected = true : obj.selected = false;
                    console.log(obj);
                    obj.text = item.name;
                    obj.value = item.code;
                    obj.imageSrc = item.icon_url;
                    if((Math.min(window.screen.width, window.screen.height) > 768) && item.name == 'ShopeePay App'){
                    
                    }else{
                        _return.push(obj);
                    }
                });
            }
            
            return _return;
        },
        dataCC: function() {
            let data = this.payment_list['Kartu Kredit'];
            let _return = [];
            if(data) {
                let _this = this;
                data.map(function(item) {
                    let obj = {};
                    (item.code == _this.last_payment) ? obj.selected = true : obj.selected = false;
                    obj.text = item.name;
                    obj.value = item.code;
                    obj.imageSrc = item.icon_url;
                    _return.push(obj);  
                });
            }
            return _return;
        },
        dataMinimarket: function() {
            let data = this.payment_list['Minimarket'];
            let _return = [];
            if(data) {
                let _this = this;
                data.map(function(item) {
                    let obj = {};
                    (item.code == _this.last_payment) ? obj.selected = true : obj.selected = false;
                    obj.text = item.name;
                    obj.value = item.code;
                    obj.imageSrc = item.icon_url;
                    _return.push(obj);
                });
            }
            return _return;
        },
    },
    methods: {
        showCollapsible: function(elem) {
            if($(elem).is(":hidden")) {
                if(this.selectedElem != "") {
                    $(this.selectedElem).slideUp(200);
                }
                this.selectedElem = elem;
                $(elem).slideDown(200);

                if(elem == "#collapsible2") {
                    // !! UNTUK DATA KARTU KREDIT
                    let cc = _.head(this.dataCC);
                    if(cc) {
                        this.$emit("selectedPayment", cc.value);
                        this.$emit("selectedTenor", null);
                        this.$emit("fee", 0);
                    }
                }
            }
        },
        triggerSelectedPayment: function() {
            let last_payment = this.last_payment;
            let payment_list = this.payment_list;
            let matched = null;
            _.find(payment_list, function(a, b) {
                _.find(a, function(x) {
                    if(x.code == last_payment) {
                        matched = b;
                    }
                });
            });
            if(matched) {
                switch (matched) {
                    case 'Virtual Account':
                        $("#radio1").prop("checked", true);
                        this.showCollapsible('#collapsible1');
                        break;
                    case 'Kartu Kredit':
                        $("#radio2").prop("checked", true);
                        this.showCollapsible('#collapsible2');
                        break;
                    case 'Cicilan':
                        $("#radio3").prop("checked", true);
                        this.showCollapsible('#collapsible3');
                        break;
                    case 'Cicilan Tanpa Kartu':
                        $("#radio4").prop("checked", true);
                        this.showCollapsible('#collapsible4');
                        break;
                    case 'E-wallet':
                        $("#radio5").prop("checked", true);
                        this.showCollapsible('#collapsible5');
                        break;
                    case 'Minimarket':
                        $("#radio6").prop("checked", true);
                        this.showCollapsible('#collapsible6');
                        break;
                    default:
                        break;
                }
            }
        },
    },
    mounted() {
        let grandtotal_num = this.grandtotal_num;
        let _this = this;
        let $label = $("<label>").text('Tidak ada data..');

        if(this.dataVA.length > 0) {
            $('#dd_VA').ddslick({
                data: this.dataVA,
                width: '100%',
                selectText: "-- Pilih Bank Penyedia --",
                imagePosition: "right",
                onSelected: function(a) {
                    _this.$emit("selectedPayment", a.selectedData.value);
                    _this.$emit("selectedTenor", null);
                    _this.$emit("fee", 0);
                }
            });
        }
        else {
            $("#collapsible1").html($label);
        }
        if(this.dataCicilan && this.dataCicilan.length > 0) {
            this.dataCicilan.map(function(item) {
                let $div = $("<div>").css("margin-bottom", "15px");
                let $lbl = $("<label>").text(item.name).css("margin-bottom", "5px");
                let $div2 = $("<div id='dd_Cicilan_"+item.name+"'>");
                $div.append($lbl);
                $div.append($div2);
                $("#collapsible3").append($div);

                let data = [];
                [3, 6, 12].map(function(tenor) {
                    let obj = {};
                    let persentase_tenor = parseFloat(item[tenor+'_month']);
                    let cicilan_perbulan = grandtotal_num / tenor;
                    let bunga = cicilan_perbulan * (persentase_tenor / 100);
                    let total = cicilan_perbulan + bunga;

                    let biaya_layanan = grandtotal_num * (persentase_tenor / 100);
                    if(item.fee_is_free == 1) biaya_layanan = biaya_layanan * 0.5;

                    (item.code == _this.last_payment) ? obj.selected = true : obj.selected = false;
                    obj.text = tenor + "x @ 0%";
                    obj.value = item.code;
                    obj.fee_is_free = item.fee_is_free;
                    obj.fee = biaya_layanan;
                    obj.tenor = tenor;
                    obj.imageSrc = item.icon_url;
                    obj.description = helpme.toRp(total) + " / bulan";
                    data.push(obj);
                });
                $("#dd_Cicilan_"+item.name).ddslick({
                    data: data,
                    width: '100%',
                    selectText: '-- Pilih --',
                    imagePosition: "right",
                    onSelected: function(a) {
                        let z = $("#dd_Cicilan_"+item.name).parent().siblings().children(".dd-container").children(".dd-select").find(".dd-selected");
                        z.html('-- Pilih --');
                        _this.$emit("selectedPayment", a.selectedData.value);
                        _this.$emit("selectedTenor", a.selectedData.tenor);
                        _this.$emit("fee", a.selectedData.fee);
                    }
                });
                $("#dd_Cicilan_"+item.name).find(".dd-option-image").css("margin-top", "10px");
            });
        }
        else {
            $("#collapsible3").html($label);
        }

        if(this.dataCicilanTanpaKartu.length > 0) {
            $('#dd_CicilanTanpaKartu').ddslick({
                data: this.dataCicilanTanpaKartu,
                width: '100%',
                selectText: "-- Pilih --",
                imagePosition: "right",
                onSelected: function(a) {
                    _this.$emit("selectedPayment", a.selectedData.value);
                    _this.$emit("selectedTenor", null);
                    _this.$emit("fee", 0);
                }
            });
        }
        else {
            $("#collapsible4").html($label);
        }

        if(this.dataEwallet.length > 0) {
            $('#dd_Ewallet').ddslick({
                data: this.dataEwallet,
                width: '100%',
                selectText: "-- Pilih --",
                imagePosition: "right",
                onSelected: function(a) {
                    _this.$emit("selectedPayment", a.selectedData.value);
                    _this.$emit("selectedTenor", null);
                    _this.$emit("fee", 0);
                }
            });
        }
        else {
            $("#collapsible5").html($label);
        }

        if(this.dataMinimarket.length > 0) {
            $('#dd_Minimarket').ddslick({
                data: this.dataMinimarket,
                width: '100%',
                selectText: "-- Pilih --",
                imagePosition: "right",
                onSelected: function(a) {
                    _this.$emit("selectedPayment", a.selectedData.value);
                    _this.$emit("selectedTenor", null);
                    _this.$emit("fee", 0);
                }
            });
        }
        else {
            $("#collapsible6").html($label);
        }

        this.triggerSelectedPayment();
    }
}
</script>

<style scoped>
input[type="radio"] {
    margin-right: 10px;
}
#accordion-payment {
    background-color: #f0f3f9;
    border-radius: 5px;
}
.card-payment {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
.card-payment:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.card-payment:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}
.card-payment:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.card-header {
    padding: 0.75rem 1.25rem;
    display:flex;
}
.card-collapsible {
    display: none;
    flex: 1 1 auto;
    padding: 8px;
    background-color: #fff;
    border-top: 1px solid rgba(0,0,0,.125);
}
.card-body {
    align-items: center;
    display: flex;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    position: relative;
    padding: 8px;
    font-size:12px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

@media (max-width: 768px) {
    .card-body {
        font-size:10px;
    }
}
.img {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 25px;
}
.img-item {
    display: block;
    width: 64px;
    height: 32px;
    border: 1px solid #d6d6d6;
    margin-right: 8px;
    margin-bottom: 8px;
    object-fit: contain;
}
.dd-options {
    position: inherit !important;
    padding :8px !important;
    z-index:1 !important;
}
.dd-desc {
    color:#323030 !important;
}

.dd-selected{
    padding :8px !important;
}

.dd-option-selected img{
    margin-top:3px;
}

</style>